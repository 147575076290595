<template>
  <div class="app-page" id="page" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['classes']
}
</script>
