<template>
  <app-panel
      event-name="openFilterType"
      ref="filterType"
      size="medium"
      class="catalog-modal"
      hide-nav
      hide-validate-button>

    <div class="panel-body" data-cy="filter-type">
      <div class="catalog-modal__heading">
        <div class="title">Récupération de votre commande</div>
        <button @click="$refs.filterType.close()"><i class="material-icons notranslate">close</i></button>
      </div>
      <div class="catalog-multiple-select modal-type">

        <div class="select__item" @click="setType('delivery')">
          <ui-radio true-value="delivery" v-model="type"></ui-radio>
          <div class="value">
            <strong>{{$t('delivery')}}</strong>
            <span>Un livreur indépendant prend en charge votre commande et vous la livre</span>
          </div>
        </div>

        <div class="select__item" @click="setType('pickup')">
          <ui-radio true-value="pickup" v-model="type"></ui-radio>
          <div class="value">
            <strong>{{$t('pickup')}}</strong>
            <span>Vous êtes notifiés dès que votre commande est prête et elle vous attendra au restaurant</span>
          </div>
        </div>

        <div class="select__action">
          <button class="btn btn-catalog w-100" data-cy="filter-type-save" @click="save">Enregistrer</button>
        </div>

      </div>
    </div>
  </app-panel>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PanelTemplate from "@/desktopComponents/PanelTemplate";

export default {
  components: {
    'app-panel': PanelTemplate
  },
  data() {
    return {
      type: 'delivery'
    }
  },
  methods: {
    ...mapActions(['setTypeAction']),
    setType(type){
      this.type = type;
    },
    save(){
      this.setTypeAction(this.type)
          .catch((e) => {
            console.log(e);
          });
      this.$refs.filterType.close()
    }
  },
  computed: {
    ...mapGetters(['filter'])
  },
  watch: {
    filter: {
      deep: true,
      handler(val){
        this.type = val.type;
      }
    }
  },
  mounted() {
    this.type = this.filter.type;
  }
}
</script>
