<template>
  <app-panel
      event-name="openFilterTime"
      ref="filterTime"
      size="semi"
      class="catalog-modal"
      @open="open"
      hide-nav
      hide-validate-button
  >
    <div class="panel-body" data-cy="filter-time">
      <div class="catalog-modal__heading">
        <div class="title">Pour quand ?</div>
        <button @click="$refs.filterTime.close()"><i class="material-icons notranslate">close</i></button>
      </div>
      <div class="catalog-multiple-select modal-type">
        <div class="select__item" @click="when = 'now'">
          <ui-radio true-value="now" v-model="when"></ui-radio>
          <div class="value">
            <strong>{{$t('catalog_time_now')}}</strong>
          </div>
        </div>
        <div class="select__item" @click="when = 'schedule_for_later'">
          <ui-radio true-value="schedule_for_later" v-model="when"></ui-radio>
          <div class="value">
            <strong>{{$t('catalog_time_later')}}</strong>
          </div>
        </div>
        <div class="select__inputs" @click="when === 'now' ? when = 'schedule_for_later' : '';">
          <select class="form-select form-select-date" v-model="date" :disabled="when !== 'schedule_for_later'">
            <option :value="d.value" v-for="d in catalogDateSlots">{{d.label}}</option>
          </select>
          <select class="form-select" :class="{'form-select-invalid': this.errors.find(e => e === 'hours')}" v-model="hours" :disabled="when !== 'schedule_for_later'">
            <option :value="null">Choisir un créneau</option>
            <option :value="h.value" v-for="h in catalogTimeSlots">{{h.label}}</option>
          </select>
        </div>
        <div class="select__action">
          <button class="btn btn-catalog w-100" data-cy="save-time-filter" @click="save">Enregistrer</button>
        </div>
      </div>
    </div>
  </app-panel>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PanelTemplate from "@/desktopComponents/PanelTemplate";

export default {
  components: {
    'app-panel': PanelTemplate
  },
  data() {
    return {
      dateSlots: [],
      hourSlots: [],
      when: 'now',
      date: null,
      hours: null,
      errors: []
    }
  },
  methods: {
    ...mapActions(['setWhenAction', 'getTimeSlotAction', 'getDateSlotAction']),
    async open(){
      this.when = this.filter.when;
      await this.getDateSlotAction();

      if(this.filter.whenDay){
        if(this.catalogDateSlots.find(ds => ds.value === this.filter.whenDay)){
          this.date = this.filter.whenDay;
        }
      }
      else{
        this.date = this.catalogDateSlots[0].value;
      }

      await this.getTimeSlotAction(this.date);

      if(this.filter.whenTimestamp){
        if(this.catalogTimeSlots.find(ds => ds.value == this.filter.whenTimestamp)){
          this.hours = parseInt(this.filter.whenTimestamp);
        }
      }
      else{
        this.hours = this.catalogTimeSlots[0].value;
      }

    },
    save(){
      this.errors = [];
      if(this.hours === null && this.when === 'schedule_for_later'){
        this.errors.push('hours');
        return;
      }

      this.setWhenAction({when: this.when, date: this.date, timestamp: this.hours})
          .catch((error) => {
            console.log(error)
          });
      this.$refs.filterTime.close()
    }
  },
  computed: {
    ...mapGetters(['filter', 'catalogDateSlots', 'catalogTimeSlots']),
  },
  watch:{
    date(d){
      this.hours = null;
      this.getTimeSlotAction(d);
    },
    when(){
      this.errors = [];
    }
  },
  mounted() {
    this.when = this.filter.when;
  }
}
</script>
