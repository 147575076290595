<template>
  <transition name="fade">
    <div class="back-to-top-button" v-if="showButton" @click="goToTop()">
      <i class="material-icons notranslate">expand_less</i>
    </div>
  </transition>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import animateScrollTo from 'animated-scroll-to';

export default {
  data() {
    return {
      showButton: false
    }
  },
  methods: {
    ...mapActions([]),
    goToTop(){
      animateScrollTo(0, {maxDuration: 1000, speed: 800});
    },
    scroll(){

      if(window.scrollY > 500){
        this.showButton = true;
      }
      else{
        this.showButton = false;
      }
      window.addEventListener('scroll', (e) => {
        if(window.scrollY > 500){
          this.showButton = true;
        }
        else{
          this.showButton = false;
        }
      });

    }
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    this.scroll();
  }
}
</script>
