<template>
  <app-page classes="catalog">

    <catalog-nav></catalog-nav>

    <template v-if="!isInHomeScreen">
      <catalog-content></catalog-content>
      <catalog-back-to-top></catalog-back-to-top>
    </template>

    <template v-if="isInHomeScreen">
      <catalog-home-landing></catalog-home-landing>
      <catalog-home-news></catalog-home-news>
     <!-- <catalog-home-categories></catalog-home-categories>-->
    </template>

    <catalog-footer></catalog-footer>

    <catalog-filter-type></catalog-filter-type>
    <catalog-filter-time></catalog-filter-time>
    <catalog-filter-address></catalog-filter-address>

  </app-page>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import '../scss/app_desktop.scss'

import Page from "@/desktopComponents/Page";
import Nav from "@/desktopComponents/Catalog/Nav";
import Content from "@/desktopComponents/Catalog/Content";
import FilterType from "@/desktopComponents/Catalog/FilterType";
import FilterTime from "@/desktopComponents/Catalog/FilterTime";
import FilterAddress from "@/desktopComponents/Catalog/FilterAddress";
import Landing from "@/desktopComponents/Catalog/Home/Landing";
import News from "@/desktopComponents/Catalog/Home/News";
import Categories from "@/desktopComponents/Catalog/Home/Categories";
import Footer from "@/desktopComponents/Catalog/Footer";
import BackToTop from "@/desktopComponents/Catalog/BackToTop";
import animateScrollTo from 'animated-scroll-to';

export default {
  components: {
    'app-page': Page,
    'catalog-nav': Nav,
    'catalog-content': Content,
    'catalog-filter-type': FilterType,
    'catalog-filter-time': FilterTime,
    'catalog-filter-address': FilterAddress,
    'catalog-home-landing': Landing,
    'catalog-home-news': News,
    'catalog-home-categories': Categories,
    'catalog-footer': Footer,
    'catalog-back-to-top': BackToTop
  },
  methods: {
    ...mapActions(['initCatalogFilterAction', 'updateFilter', 'copyAddressByTokenAction', 'getFilteredMerchantsCatalogAction', 'setSubCategoriesAction', 'getHomeAction', 'setCatalogAddressAction']),
    setDefaultFavicon(){
      document.getElementById('ico').href = '/favicon.ico';
      document.getElementById('i180').href = '/apple-touch-icon.png';
      document.getElementById('i32').href = '/icon-32.png';
      document.getElementById('i16').href = '/icon-16.png';
    }
  },
  computed: {
    ...mapGetters(['modal', 'isInHomeScreen'])
  },
  data(){
    return {
      init: false
    }
  },
  watch:{
    modal(val){
      if(val.length === 0){
        document.body.style.overflowY = "auto";
        document.body.style.position = "relative";
      }
      else {
        document.body.style.overflowY = "hidden";
        document.body.style.position = "relative";
      }
    }
  },
  async mounted() {

    if(this.$route.query.category_id){
      this.updateFilter({subCategories:[parseInt(this.$route.query.category_id)]});
    }
    if(this.$route.query.lat && this.$route.query.lng && this.$route.query.addressName){
      await this.setCatalogAddressAction( {address: this.$route.query.addressName, city: null, zip: null, country: null, lat:this.$route.query.lat, lng:this.$route.query.lng, precise: false, reload: false})
    }
    else if(this.$route.query.address_token){
      await this.copyAddressByTokenAction({token: this.$route.query.address_token})
    }
    if(this.$route.query){
      //this.$router.push('/');
    }
    await this.initCatalogFilterAction();

    animateScrollTo(0, {maxDuration: 1});

    this.init = true;
    if(!this.isInHomeScreen){
     // await this.getFilteredMerchantsCatalogAction();
    }
    else{
      this.getHomeAction();
    }
    this.setDefaultFavicon();
  }
}
</script>
