<template>
    <div class="catalog-new-merchants" data-cy="home-news">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="catalog-title">
              <span>Les Nouveautés</span>
            </div>
          </div>
          <div class="col-lg-4"  v-for="merchant in newMerchants">
            <a :href="'/' + merchant.slug" class="item__merchant" :style="{'background-image': 'url('+merchant.image.path+')'}">
              <div class="merchant__info">
                <strong>{{merchant.name}}</strong>
                <span>{{merchant.city}} · {{merchant.category}}</span>
              </div>
            </a>
          </div>

        </div>
      </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {

    }
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['newMerchants'])
  }
}
</script>
