<template>
  <div class="catalog-category-item" :class="{'catalog-category-item-active': category.id === filter.category}">
    <div class="icon">
      <img :src="category.image.path" alt="">
    </div>
    <div class="text">{{ category.name }}</div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  props: ['category'],
  data(){
    return {

    }
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['filter'])
  }
}
</script>
