<template>
  <div class="catalog__content">
    <section class="container" id="loading" v-if="catalogLoading && !autoReload">
      <div class="content__loading">
        <loader></loader>
        <small>Chargement de nos super restos...</small>
      </div>
    </section>
    <div class="container" id="reload" v-if="autoReload">
      <div class="content__message">
        <strong>Oops... On dirait qu'il y a un problème, merci de réessayer dans quelques instants 👷‍♂</strong>
        <small>(Actualisation automatique dans {{autoReloadDuration}} secondes)</small>
      </div>
    </div>

    <div class="container container-catalog-content" v-if="!catalogLoading">
      <div class="row">

        <div class="col-12 mt-5" v-if="showCategories.length > 0 && filter.category === 'all' && !filter.search && !catalogMerchantLoading && !currentCategory && filteredMerchantsUnavailable.length > 0 && filteredMerchantsAvailable.length > 0">
          <div class="merchants-section__title">
            <strong>Explorer</strong>
            <button @click="toggleShowAllCategories" class="category-list-button" v-if="!showAllCategories">Voir tout</button>
            <button @click="toggleShowAllCategories" class="category-list-button" v-if="showAllCategories">Fermer</button>
          </div>
          <div class="category-list" :class="{'category-list-hidden': !showAllCategories}">

            <div class="category-item bg-dark"
                 @click="selectCategory(c)"
                 :style="'background-image: url('+c.image+')'"
                 v-if="c.image"
                 v-for="(c,i) in showCategories"
                 :key="i">

              <div class="filter">
                <strong>{{c.name}}</strong>
              </div>
            </div>

          </div>
        </div>



        <div class="col-12 mt-5">
          <div class="container" v-if="catalogMerchantLoading">
            <div class="content__merchant-loading">
              <loader></loader>
            </div>
          </div>

          <div class="content__search-no-results" v-if="!catalogMerchantLoading && filter.category !== 'all' && filter.search && filteredMerchantsUnavailable.length === 0 && filteredMerchantsAvailable.length === 0">
            <img src="https://delicity.b-cdn.net/public/assets/catalog/no_results.gif">
            <strong>Pas de résultat pour votre recherche « {{filter.search}} » dans {{ categories.find(c => c.id === filter.category).name }}</strong>
            <div class="action">
              <ui-button color="primary" @click="searchOnAllPlatform">Rechercher sur tout delicity</ui-button>
            </div>
          </div>

          <div class="content__search-no-results" v-if="!catalogMerchantLoading && filter.category === 'all' && filter.search && filteredMerchantsUnavailable.length === 0 && filteredMerchantsAvailable.length === 0">
            <img src="https://delicity.b-cdn.net/public/assets/catalog/no_results.gif">
            <strong data-cy="search-title">Pas de résultat pour votre recherche « {{filter.search}} »</strong>
            <div class="action">
              <ui-button color="primary" @click="resetFilters">Voir tous les restaurants</ui-button>
            </div>
          </div>

          <div class="content__search-no-results" v-if="!catalogMerchantLoading && filteredMerchantsUnavailable.length === 0 && filteredMerchantsAvailable.length === 0 && !filter.search">
            <strong v-if="filter.category !== 'all'">{{$t('no_merchant_in_category', {categoryName: categories.find(c => c.id === filter.category).name})}}</strong>
            <div class="action" v-if="filter.category !== 'all'">
              <ui-button color="primary" @click="resetFilters">Voir tous les restaurants éligible</ui-button>
            </div>
            <strong v-if="filter.category === 'all'">Nous n'avons pas trouvé de restaurants partenaires Delicity proche de chez vous... 😥</strong>
            <div class="action" v-if="filter.category === 'all'">
              <ui-button color="primary" @click="$root.$emit('openFilterAddress')">Changer d'adresse ?</ui-button>
            </div>
          </div>

          <div class="content__merchants-section" v-if="!catalogMerchantLoading">

            <div class="merchants-section__title mt-0" v-if="filter.category === 'all' && filter.search && (filteredMerchantsUnavailable.length > 0 || filteredMerchantsAvailable.length > 0)">
              <strong data-cy="search-title">Résultat de votre recherche « {{filter.search}} »</strong>
            </div>


            <div class="merchants-section__title mt-0" v-if="availableDelivery.length > 0 && filter.category === 'all' && !currentCategory && !filter.search && (filteredMerchantsUnavailable.length > 0 || filteredMerchantsAvailable.length > 0)">
              <strong>A PROXIMITÉ</strong>
            </div>

            <div class="merchants-section__title mt-0" v-if="filter.category === 'all' && currentCategory && !filter.search && (filteredMerchantsUnavailable.length > 0 || filteredMerchantsAvailable.length > 0)">
              <button class="category-list-button-back mr-3" @click="resetCategory()" v-if="!$route.query.hide_back_button">
                <i class="material-icons notranslate">arrow_back</i>
                Retour
              </button>
              <strong>{{currentCategory.name}}</strong>
            </div>

            <div class="merchant-section__items">
              <div class="row">


                <template v-if="filter.type === 'delivery'">

                  <div class="col-lg-4 col-md-6" v-for="merchant in availableDelivery">
                    <catalog-merchant-item :merchant="merchant"></catalog-merchant-item>
                  </div>

                  <div class="col-12" v-if="availablePickupOnly.length > 0">
                    <div class="merchants-section__title">
                      <strong>À emporter uniquement</strong>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6" v-for="merchant in availablePickupOnly">
                    <catalog-merchant-item :merchant="merchant"></catalog-merchant-item>
                  </div>

                </template>
                <template v-else>
                  <div class="col-lg-4 col-md-6" v-for="merchant in filteredMerchantsAvailable">
                    <catalog-merchant-item :merchant="merchant"></catalog-merchant-item>
                  </div>
                </template>




              </div>
            </div>
          </div>




          <div class="content__merchants-section" v-if="filteredMerchantsUnavailable.length > 0 && !catalogMerchantLoading">
            <div class="merchants-section__title">
              <strong>Ouvre bientôt</strong>
            </div>
            <div class="merchant-section__items">
              <div class="row">
                <div class="col-lg-4 col-md-6" v-for="merchant in filteredMerchantsUnavailable">
                  <catalog-merchant-item :merchant="merchant"></catalog-merchant-item>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import CategoryItem from "@/desktopComponents/Catalog/CategoryItem";
import MerchantItem from "@/desktopComponents/Catalog/MerchantItem";
import Footer from "@/desktopComponents/Catalog/Footer";
import Loader from "@/components/Loader";

export default {
  components: {
    'catalog-category-item': CategoryItem,
    'catalog-merchant-item': MerchantItem,
    'loader': Loader,
  },
  data(){
    return {
      autoReload: false,
      showCategories: [],
      showAllCategories: false,
      autoReloadDuration: 15
    }
  },
  methods: {
    ...mapActions(['getFilteredMerchantsCatalogAction', 'setCategoryAction', 'resetFiltersAction', 'updateFilter', 'initCatalogFilterAction', 'setSubCategoriesAction']),
    setCategory(category){
      this.setCategoryAction(category);
    },

    searchOnAllPlatform(){
      this.setSearchAction({search:this.filter.search, change:true});
    },
    resetFilters(){
      this.resetFiltersAction()
    },

    selectCategory(cat){
      this.setSubCategoriesAction([cat.id]);
    },
    resetCategory(){
      this.setSubCategoriesAction([]);
    },

    getFeaturedSubCategories(){
      let categories = [];
      this.filteredMerchants.forEach((cat) => {
        cat.subCategoriesIds.forEach((id) => {
          const obj = categories.find(e => e.id === id)
          if(obj){
            if(cat.available){
              obj.sort += 100;
              obj.open++;
            }
            else{
              obj.sort++;
            }
            obj.count++;
          }
          else{
            const cat = this.subCategories.find(c => c.id === id);
            if(cat.image.path && cat.important){
              categories.push({id: id, count:1, sort:0, open: 0, name:cat.name, image: cat.image.path});
            }
          }
        })
      });
      categories = categories.sort(function (a, b) {
        return b.sort - a.sort;
      });

      if(this.showAllCategories === false){
        categories = categories.slice(0,8)
      }
      else{
        categories = categories.slice(0,24)
      }

      this.showCategories = categories;
    },
    toggleShowAllCategories(){
      this.showAllCategories = !this.showAllCategories;
      console.log(this.showAllCategories);
      this.getFeaturedSubCategories();
    }
  },
  computed: {
    ...mapGetters(['filteredMerchantsAvailable', 'filteredMerchantsUnavailable', 'filteredMerchants', 'subCategories', 'categories', 'filter', 'catalogLoading', 'catalogMerchantLoading']),
    availableDelivery(){
      return this.filteredMerchantsAvailable.filter(e => e.openInDelivery === true);
    },
    availablePickupOnly(){
      return this.filteredMerchantsAvailable.filter(e => e.openInDelivery === false && e.openInPickup === true);
    },
    currentCategory(){

      if(this.filter.subCategories.length > 0){
        return this.subCategories.find(c => c.id === this.filter.subCategories[0])
      }
      return null;
    }
  },
  async mounted(){

    await this.getFilteredMerchantsCatalogAction()
        .catch(() => {
          setInterval(() => {
            this.autoReload = true;
            this.autoReloadDuration = this.autoReloadDuration - 1;
            if(this.autoReloadDuration === 0){
              location.reload();
            }
          }, 1000)
        });


    this.getFeaturedSubCategories();

  }
}
</script>
