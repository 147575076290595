<template>
  <app-panel
      event-name="openFilterAddress"
      size="medium"
      class="catalog-modal"
      hide-nav
      ref="filterAddressModal"
      @open="open"
      hide-validate-button>

    <div class="panel-body" v-if="catalogData">

      <div class="catalog-modal__heading">
        <div class="title">Votre adresse</div>
        <button @click="$refs.filterAddressModal.close()"><i class="material-icons notranslate">close</i></button>
      </div>

      <div class="catalog-multiple-select modal-type">
        <div class="w-100 text-right">
          <small class="text-danger" v-if="locationPermissionError">{{locationPermissionError}}</small>
        </div>

        <div class="mb-4">
          <div class="catalog-address-field" :class="{'catalog-address-field-error': error}">
            <i class="material-icons notranslate icon">place</i>
            <vue-google-autocomplete
                id="catalogFilterAddressField"
                classname="autocomplete"
                country="fr"
                :placeholder="$t('enter_your_address')"
                ref="catalogAutocomplete"
                v-on:placechanged="selectAddress">
            </vue-google-autocomplete>
            <a href="#" @click.prevent="currentPosition" class="location" :class="{'location-error': locationPermissionError}" v-if="!isCurrentPositionLoading">
              <i class="material-icons notranslate-outlined">near_me</i>Utiliser ma position
            </a>
            <span  class="location" v-if="isCurrentPositionLoading">
              Chargement...
            </span>
          </div>
          <span v-if="error === 'address_no_street_number'" class="catalog-address-field-error-message">Vous n'avez pas indiqué le numero de la rue</span>
        </div>

        <div class="select__item" @click="selectedAddress = add.token" v-for="add in catalogAddresses">
          <ui-radio :true-value="add.token" v-model="selectedAddress"></ui-radio>
          <div class="value">
            <span class="text-dark">{{add.address}}, {{add.zip}} {{add.city}}</span>
          </div>
        </div>

        <div class="select__action">
          <button class="btn btn-catalog w-100" @click="save" :disabled="error">Enregistrer</button>
        </div>
      </div>
    </div>
  </app-panel>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PanelTemplate from "@/desktopComponents/PanelTemplate";
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: {
    'app-panel': PanelTemplate,
    'vue-google-autocomplete': VueGoogleAutocomplete,
  },
  data() {
    return {
      autocompleteData: false,
      error: false,
      loading: false,
      selectedAddress: '',
      isCurrentPosition: false,
      isCurrentPositionLoading: false,
      locationPermissionError: false,
    }
  },
  methods: {
    ...mapActions(['setCatalogAddressAction', 'parseCatalogAddressAction', 'setWhereAction', 'getUserCurrentPositionAction']),
    async selectAddress(e){ // Add address by autocomplete
      this.loading = true;
      this.error = false;
      this.autocompleteData = true;

      if(!e.street_number){
        this.error = 'address_no_street_number';
        this.loading = false;
        return;
      }

      await this.setCatalogAddressAction({address: e.street_number + ' ' + e.route, city: e.locality, zip: e.postal_code, country: e.country, lat:e.latitude, lng:e.longitude});
      this.$refs.filterAddressModal.close()
      this.loading = false;
    },
    currentPosition(){
      this.error = false;
      this.isCurrentPositionLoading = true;
      this.getUserCurrentPositionAction({reload:true})
          .then((address) => {
            document.getElementById('catalogFilterAddressField').value = address;
            this.isCurrentPosition = true;
            this.isCurrentPositionLoading = false;
            this.$refs.filterAddressModal.close()
          })
          .catch((error_code) => {
            this.isCurrentPositionLoading = false;
            this.locationPermissionError = this.$t(error_code) + '...';
          });
    },
    parseAddress(){ // Add address by address value
      this.error = false;
      const address = document.getElementById('catalogAutocomplete').value;
      if(address === '' || address === null){
        this.error = 'address_no_address';
        return;
      }

      this.loading = true;
      setTimeout(() => { // Avoid double send (Autocomplete + server parse)
        if(!this.autocompleteData){ // Send to api server

          this.parseCatalogAddressAction({address: address})
              .then(() => {
                this.$refs.filterAddressModal.close()
                this.loading = false;
              })
              .catch((error) => {
                this.error = error;
                this.loading = false;
              });
        }
      }, 500);
    },
    save(){
      if(this.selectedAddress !== this.catalogData.addressToken){
        this.setWhereAction({token:this.selectedAddress, reload:true});
      }
      this.$refs.filterAddressModal.close()
      this.error = false;
    },
    open(){
      this.$refs.catalogAutocomplete.clear();
      this.selectedAddress = this.catalogData.addressToken;
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['filter', 'catalogData', 'user', 'catalogAddresses'])
  }
}
</script>
